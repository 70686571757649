<template>
  <div class="mb-5">
    <h5 class="text-sm">Deactivated Deals</h5>
    <datatable
      :data="deactivatedDealsResource.data"
      :columns="deactivatedDealsResource.columns"
      :actions="deactivatedDealsResource.actions"
      :loading="deactivatedDealsResource.loading"
    />
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      deactivatedDealsResource: this.$options.resource([], {
        columns: [
          {
            name: 'type',
            th: 'Merchant Discount Type'
          },
          {
            name: 'commission',
            th: 'Merchant Discount',
            render: settings =>
              settings?.type === 'percentage'
                ? `${settings?.commission}%`
                : this.$options.filters.formatAmount(settings?.commission)
          },
          {
            name: 'credpal_commission_type',
            th: 'Credpal Discount Type'
          },
          {
            name: 'credpal_commission',
            th: 'Credpal Discount',
            render: settings =>
              settings?.credpal_commission_type === 'percentage'
                ? `${settings?.credpal_commission}%`
                : this.$options.filters.formatAmount(
                    settings?.credpal_commission
                  )
          },
          {
            name: 'minimum',
            th: 'Minimum Amount',
            render: settings =>
              this.$options.filters.formatAmount(settings?.minimum) ?? 0
          },
          {
            name: 'maximum',
            th: 'Maximum Amount',
            render: settings =>
              this.$options.filters.formatAmount(settings?.maximum) ?? 0
          },
          {
            name: 'maximum_discount_value',
            th: 'Maximum Discount Value',
            render: settings =>
              this.$options.filters.formatAmount(
                settings?.maximum_discount_value
              )
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: settings =>
              this.$options.filters.dateFormat(
                settings?.created_at,
                'D, dd M Y'
              )
          },
          {
            name: 'deactivated_at',
            th: 'Deactivated At',
            render: settings =>
              this.$options.filters.dateFormat(
                settings?.deactivated_at,
                'D, dd M Y'
              )
          }
        ],
        actions: [
          {
            text: 'Activate',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.activateDeal
          }
        ]
      })
    };
  },
  computed: {
    profileId() {
      return this.profile?.id;
    }
  },
  beforeMount() {
    this.deactivatedDeals();
  },
  methods: {
    async activateDeal({ id }) {
      await this.sendRequest(
        'admin.merchants.changeDealStatus',
        id,
        'activate',
        {
          success: () => this.$emit('success'),
          error: error => console.log(error)
        }
      );
    },
    async deactivatedDeals() {
      this.deactivatedDealsResource.loading = true;
      await this.sendRequest(
        'admin.merchants.deactivatedDeals',
        this.profileId,
        {
          success: response =>
            (this.deactivatedDealsResource.data = response.data.data),
          error: error => console.log(error)
        }
      );
      this.deactivatedDealsResource.loading = false;
    }
  }
};
</script>
